import { render, staticRenderFns } from "./Campaign.vue?vue&type=template&id=6c10fd25&scoped=true"
import script from "./Campaign.vue?vue&type=script&lang=js"
export * from "./Campaign.vue?vue&type=script&lang=js"
import style0 from "./Campaign.vue?vue&type=style&index=0&id=6c10fd25&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c10fd25",
  null
  
)

export default component.exports