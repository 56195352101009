<template>
	<div class="campaign-box">
		<div class="campaign-card campaign-card-1 ">
			<div class="tip-box">
				<span class="open" v-if="true">Open</span>
				<span class="closed" v-else>Closed</span>
			</div>
			<p class="card-1-1">Bridge Campaign</p>
			<div class="card-1-2 flex-center">
				<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/Frame.png" alt="" srcset="" />
				<span>Medals</span>
			</div>
			<p class="card-1-3">
				Jan.22th, 2024 - Main Net Launch
			</p>
			<a-button type="primary" @click="$router.push({ name: 'BridgeCampaign' })">
				Join Now
			</a-button>
			
		</div>
		<div class="campaign-card-2-box flex-between">
			<div class="campaign-card campaign-card-2">
				<div class="tip-box">
					<span class="open" v-if="true">Open</span>
					<span class="closed" v-else>Closed</span>
				</div>
				<p class="card-1-1">Faucet</p>
				<div class="card-1-2 flex-center">
					<img src="https://wami-wod.oss-cn-beijing.aliyuncs.com/img/wod-1.png" alt="" srcset="" />
					<span>$WOD</span>
				</div>
				<p class="card-1-3">
					Started from Dec.11th, 2023
				</p>
				<a-button type="primary" @click="$router.push({ name: 'Faucet' })">
					Join Now
				</a-button>
			</div>
			<div class="campaign-card campaign-card-3">
				<div class="tip-box">
					<span class="open" v-if="true">Open</span>
					<span class="closed" v-else>Closed</span>
				</div>
				<p class="card-1-1">
					<span>$EON </span>
					<span>Airdrop</span>
				</p>
				<div class="card-1-2 flex-center">
					<img src="https://wodswap.io/eon.png" alt="" srcset="" />
					<span>$EON</span>
				</div>
				<p class="card-1-3">
					Jan.22th, 2024 - Jan.26th, 2024
				</p>
				<a-button type="primary" @click="$router.push({ name: 'Airdrop' })">
					Join Now
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
	export default {
		name: 'Name',
		data() {
			return {

			}
		},
		computed: {

		},
		mounted() {

		},
		methods: {

		}
	}
</script>

<style lang="scss" scoped>
	.campaign-box {
		position: relative;

		.campaign-card {
			flex: 1;
			border: 2px solid rgba(46, 42, 65, 1);
			border-radius: 20px;
			position: relative;
			top: 0;
			text-align: center;
			padding: 60px 0 50px;
			
			// background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box3.jpg') no-repeat;
			// background-size: 100% 100%;
			
			
			background: radial-gradient(67.76% 72.76% at 50% 10.33%, #000000 23%, rgba(4, 1, 19, 0.5) 66%, #3D54CA 128%),
				linear-gradient(0deg, #2E2A41, #2E2A41),
				linear-gradient(0deg, #D7CCF6 -8.91%, rgba(19, 8, 48, 0) 45.33%);

			@screen md {
				padding: 40px 0;
			}

			&:hover {
				transition: all 1s;
				box-shadow: 0px 50px 100px 0px rgba(102, 106, 179, 0.5);
				top: -10px;

				&::after {
					opacity: 1;
				}
			}

			.tip-box {
				position: absolute;
				top: 0px;
				right: 0px;
				.open, .closed {
					padding: 4px 25px;
					color: #0A0D23;
					font-size: 16px;
					font-weight: 590;
					display: inline-block;
					border-radius: 0px 20px;
					/* border: 1px solid rgba(255, 255, 255, 0.20); */
				}
				.open {
					background: linear-gradient(131deg, #C4923E -1.25%, #E4BE68 28.42%, #F8DA83 53.61%, #FEE07E 63.92%, #F3C433 82.64%, #E0AE2D 93.52%);
				}
				.closed {
					border-radius: 0px 20px;
					background: #6c6c6c;
				}
			}

			.card-1-1 {
				margin: 0;
				text-align: center;
				
				font-size: 80px;
				font-weight: 800;
				line-height: 92px;
				letter-spacing: 0.01em;
				color: #fff;


				@screen md {
					color: #FFF;
					
					font-size: 32px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					/* 38.4px */
					letter-spacing: 0.32px;
				}

			}

			.card-1-2 {
				padding: 30px 0;

				img {
					width: 32px;
					height: 32px;
					margin-right: 10px;
				}

				span {
					
					font-size: 28px;
					font-weight: 590;
					line-height: 24px;
					letter-spacing: 0px;
					text-align: left;
					color: rgba(255, 255, 255, 1);

				}

				@screen md {
					padding: 16px 0;

					img {
						width: 20px;
						height: 20px;
					}

					span {
						color: #FFF;
						
						font-size: 16px;
						font-style: normal;
						font-weight: 590;
						line-height: 24px;
						/* 150% */
					}
				}
			}

			.card-1-3 {
				padding-bottom: 80px;
				
				font-size: 24px;
				font-weight: 510;
				line-height: 29px;
				letter-spacing: 0px;
				text-align: center;
				color: rgba(255, 255, 255, 0.8);
				margin: 0;

				@screen md {
					padding-bottom: 40px;
					color: rgba(255, 255, 255, 0.80);
					
					font-size: 16px;
					font-style: normal;
					font-weight: 510;
					line-height: normal;
				}

			}

			.ant-btn-primary {
				width: 420px;
				height: 56px;
				box-sizing: border-box;
				border-radius: 100px;
				line-height: 56px;
				border: 0;
				background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

				
				font-size: 24px;
				font-weight: 700;

				@screen md {
					width: 240px;
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}

			}



		}
	}

	.campaign-card-2-box {
		margin-top: 60px;


		.campaign-card-2,
		.campaign-card-3 {
			padding: 40px 0;

			.card-1-1 {
				
				font-size: 54px;
				font-weight: 800;
				line-height: 68px;
				letter-spacing: 0em;
				text-align: center;


				span:nth-of-type(1) {
					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}



				@screen md {
					color: #FFF;
					
					font-size: 32px;
					font-style: normal;
					font-weight: 800;
					line-height: 120%;
					/* 38.4px */
				}
			}

			.card-1-2 {
				padding: 20px 0;

				img {
					width: 40px;
					height: 40px;
					border-radius: 100px;

				}

				span {
					
					font-size: 24px;
					font-weight: 800;
					line-height: 29px;
					letter-spacing: 0em;
					text-align: left;

					background-image: linear-gradient(to right, #33C4F2, #1F35FD);
					-webkit-background-clip: text;
					background-clip: text;
					color: transparent;
				}

				@screen md {
					padding: 16px 0;

					img {
						width: 24px;
						height: 24px;
					}

					span {
						
						font-size: 20px;
						font-style: normal;
						font-weight: 800;
						line-height: 120%;
						/* 24px */
					}
				}
			}

			.card-1-3 {
				padding-bottom: 60px;
				
				font-size: 24px;
				font-weight: 510;
				line-height: 29px;
				letter-spacing: 0px;
				color: rgba(255, 255, 255, 1);


				@screen md {
					margin: 0;
					padding-bottom: 47px;
					color: rgba(255, 255, 255, 0.80);
					
					font-size: 16px;
					font-style: normal;
					font-weight: 510;
					line-height: normal;
				}

			}

			.ant-btn-primary {
				width: 240px;
				height: 44px;
				box-sizing: border-box;
				border-radius: 100px;
				line-height: 44px;
				border: 0;
				background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

				
				font-size: 16px;
				font-weight: 700;

				@screen md {
					width: 240px;
					height: 42px;
					line-height: 42px;
					font-size: 14px;
				}

			}

		}

		.campaign-card-2 {
			border: unset;
			background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box1.png') no-repeat;
			background-size: 100% 100%;
			// background: radial-gradient(89.29% 89.29% at 49.94% 15.38%, #000000 0%, rgba(4, 1, 19, 0) 39.95%, #ca9f3dbd 100%),
			// 	linear-gradient(0deg, #2E2A41, #2E2A41),
			// 	linear-gradient(0deg, #F6EACC -5.36%, rgba(48, 10, 8, 0) 54.53%);.
			
			&:hover {
				transition: all 0.5s;
				box-shadow: 0px 50px 100px 0px rgb(246,234,204 ,0.37);
				top: -10px;
			
				&::after {
					opacity: 1;
				}
			}
		}

		.campaign-card-3 {
			border: unset;
			margin-left: 34px;
			background: url('https://wami-wod.oss-cn-beijing.aliyuncs.com/img/FAUCET-box2.png') no-repeat;
			background-size: 100% 100%;
			// background: radial-gradient(81.46% 81.46% at 49.94% 21.57%, #000000 0%, rgba(4, 1, 19, 0) 39.26%, #62ca3d69 100%),
			// 	linear-gradient(0deg, #2E2A41, #2E2A41),
			// 	linear-gradient(0deg, #E6F6CC -7.14%, rgba(19, 8, 48, 0) 50%);
			&:hover {
				transition: all 0.5s;
				box-shadow: 0px 50px 100px 0px rgb(230,246,204 ,0.43);
				top: -10px;
			
				&::after {
					opacity: 1;
				}
			}
		}



		@screen md {
			margin-top: 0;
			display: flex;
			flex-direction: column;

			.campaign-card-2,
			.campaign-card-3 {
				width: 100%;
				margin-top: 20px;
				flex: unset;
			}

			.campaign-card-3 {
				margin: 0;
				margin-top: 20px;
			}
		}



	}
</style>